@import url("https://use.typekit.net/jkr0kxq.css");
/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #e41324;
$black: rgb(25, 25, 25);
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: "rig-solid-bold-reverse", "franklin-gothic-urw-cond", "Futura", "Helvetica", sans-serif;


// .ordering-menu {
//   background: $black;
//   /* background: none; */
//   color: white;

// }
.greatgrill {
  img.nav-bar-logo {
    display: block;
    // padding-left: 0.8em;
  }
  .cart.minimized {
    background-color: #330033;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    padding-bottom: 0.35em;
    border: none;
    /* background: black; */
    border-bottom: 2px solid rgba(255,255,255,0.8);
    /* background: repeating-linear-gradient(
    90deg,
    #dd0016,
    #dd0016 40px,
    #eee 40px,
    #eee 80px
    ); */
  }

  .landing-page, .top-banner {
    font-family: $sigFont;

    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    background: url(https://afag.imgix.net/the-great-grill-back-to-the-50s/table-of-food.jpeg?w=1200);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  .landing-page .text {
    color: white;
    background: rgba(0,0,0,0.5);

    padding: 0.5em 0.8em;
    img {
      width: 90%;
      max-width: 700px;
      display: block;
      margin: 0 auto;
    }
  }

  a.checkout-button.button {
    background: $red;
    font-family: $sigFont;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid rgb(0, 0, 0);

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  font-family: $sigFont;
  font-weight: 400;
  font-size: 2.3em;
  color: $red;
  background: none;

}
// .category-name .text::before {
  //   content: "• "
  // }
  // .category-name .text::after {
    //   content: " •"
    // }
    .top-banner {
      // height: 30vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right center;
      color: white;
      text-shadow: 1px 1px 0 $red;
    }

    .pages-nav {
      text-transform: uppercase;
      font-weight: 700;
      font-family: $sigFont;
      background: #111;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 480px) {
        font-size: 4vw;
      }
    }

    a.page-link {
      text-decoration: none;
      color: white;
      display: block;
      padding: $paddingY 0.3em;
      padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
      // box-sizing: border-box;
      border-bottom: $borderWidth solid #111;
      &:hover, &.active {
        border-bottom: $borderWidth solid $red;
        color: $red;
      }
    }

    .full-menu-link-wrapper {
      padding: 1em 0;
      text-align: center;
      a.button {
        background: $red;
        color: white;
      }
    }

    .menu-item {
      .food-name {
        font-family: $sigFont;
        font-size: 1em;
        font-weight: 500;
        // color: $red;
      }
    }

    .add-to-cart-button button {
      font-family: $sigFont;
      border: 2px solid $red;
      // background: $black;
      color: $red;
    }
}
